<template>
	 <div class="tinymce-box">
		<editor v-model="myValue"
		  :init="init"
		  :disabled="disabled"
		  :setup="init.setup"
		  @onClick="onClick">
		</editor>
	 </div>
</template>

<script>
import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image'// 插入上传图片插件
import 'tinymce/plugins/media'// 插入视频插件
import 'tinymce/plugins/table'// 插入表格插件
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件
import 'tinymce/icons/default/icons'// 图标
export default {
	inject:['app'],
    components:{
        Editor
    },
    name:'tinymce',
    props: {
        value: {
          type: String,
          default: ''
        },
        disabled: {
          type: Boolean,
          default: false
        },
        plugins: {
          type: [String, Array],
          default: 'lists image media table wordcount'
        },
        toolbar: {
          type: [String, Array],
          default: 'fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image imageUpload media table | removeformat fontselect'
        }
    },
    data(){
        return{
            init: {
                language_url: '/tinymce/langs/zh_CN.js',
                language: 'zh_CN',
                skin_url: '/tinymce/skins/ui/oxide',
                // skin_url: 'tinymce/skins/ui/oxide-dark',//暗色系
				content_css:'/tinymce/skins/content/default/content.css',
                height: 500,
                plugins: this.plugins,
                toolbar: this.toolbar,
                branding: false,
                menubar: false,
                paste_data_images: true, // 允许粘贴图像
                images_upload_url: '/manage/images/upload',
                images_upload_base_path: '/upload',
                // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
                // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
                images_upload_handler: (blobInfo, success, failure) => {
                  // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
                  // success(img)
				  const formdata = new FormData()
				  formdata.append("file",blobInfo.blob())
				  this.axios.post('/manage/images/upload', formdata).then(res => {
				    if (res.status) {
				      success(res.data.url)
				    } else {
				      this.$message.error(res.msg)
				    }
				  })
                },
				// 自定义按钮
				// setup:(editor)=>{
				// 	editor.ui.registry.addButton('imageUpload',{
				// 		tooltip: '插入图片',
				// 		icon: 'image',
				// 		onAction:(e)=>{
				// 			console.log(e)
							// this.app.chooseImage((data)=>{
							// 	// 插入到编辑器中
							// 	data.forEach(item=>{
							// 		editor.insertContent(`&nbsp;<img src="${item.url}">&nbsp;`)
							// 	})
							// },100)
				// 		}
				// 	})
				// }
              },
              myValue: this.value
        }
    },
    mounted () {
        tinymce.init({})
		// this.getQiniuToken();
    },
    methods: {
        // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
        // 需要什么事件可以自己增加
        onClick (e) {
          this.$emit('onClick', e, tinymce)
        },
        // 可以添加一些自己的自定义事件，如清空内容
        clear () {
          this.myValue = ''
        },
    },
    watch: {
        value (newValue) {
          this.myValue = newValue
        },
        myValue (newValue) {
          this.$emit('input', newValue)
        }
    }
}
</script>

<style>
</style>
